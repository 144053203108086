import Connectivity from '../../src/assets/pdp/Connectivity.svg'
import Entertainment from '../../src/assets/pdp/Entertainment.svg'
import Hospitals from '../../src/assets/pdp/Hospitals.svg'
import Hotels from '../../src/assets/pdp/Hotels.svg'
import Schools from '../../src/assets/pdp/Schools.svg'
import Nearby from '../../src/assets/pdp/Nearby.svg'
import Parkgarden from '../../src/assets/pdp/Parkgarden.svg'
import TouristHotspot from '../../src/assets/pdp/TouristHotspot.svg'
import shopping from '../../src/assets/pdp/shopping.svg'
import Offices from '../../src/assets/pdp/Offices.svg'

export const divumfeaturesData = [
    {
        name: "Transportation",
        src: Connectivity,
        links: [
            "Western Express Highway - 7 Mins",
            "Metro Station - 7 Mins",
            "International Airport - 20 Mins",

        ]
    },
    {
        name: "Business Parks",
        src: Hotels,
        links: [
            "Infinity Park - 4 Mins",
            "Commerz Business Park - 7 Mins",
            "International Business Park - 8 Mins",
            "Vakratunda Corporate Park - 12 Mins",
            "Nesco IT Park - 20 Mins",
            "Nirlon Knowledge Park  - 22 Mins",
            "Morgan Stanley - 7 Mins"

        ]
    },
    {
        name: "Shopping",
        src: shopping,
        links: [
            "Gokuldham Shopping Centre - 2 Mins",
            "Oberoi Mall - 7 Mins",
            "Film City - 7 Mins",
            "PVR Oberoi - 7 Mins",
            "Westin Hotel - 7 Mins",
            "Residency Sarovar Portico - 15 Mins",
            "Inorbit Mall - 18 Mins",
            "Hypercity - 20 Mins",


        ]
    },
    {
        name: "Schools and Educational Institutions",
        src: Schools,
        links: [
            "Gokuldham High School & Jr. College - 5 Mins",
            "RYAN International School - 6 Mins",
            "Playmate Primary Pre School  - 7 Mins",
            "Oberoi International School - 7 Mins",
            "Yashodham High & Jr. College - 9 Mins",
            "Lakshdham High School - 10 Mins",

        ]
    },
    {
        name: "Health",
        src: Hospitals,
        links: [
            "Lifeline Medicare Hospital - 7 Mins",
            "Gokuldham Medical Centre - 7 Mins",
            "E.S.I.S Hospital - 15 Mins",
            "ZENITH Multispecality Hospital - 15 Mins",


        ]
    },
]

export const lumaFeaturesData = [
    {
        name: "Connectivity",
        src: Connectivity,
        links: [
            "International Airport - 5 Mins",
            "Western Express Highway - 8 Mins",
            "Marol Metro Station - 8 Mins",

        ]
    },
    {
        name: "Hotels & Recreations",
        src: Hotels,
        links: [
            "ITC Maratha - 1 Mins",
            "JW Marriott Mumbai Sahar - 5 Mins",
            "Waterstone Club - 5 Mins",
            "Leela Mumbai Resort Style Business Hotel - 6 Mins",
            "Fairfield By Marriott Mumbai International Airport - 8 Mins",

        ]
    },
    {
        name: "Hospitals",
        src: Hospitals,
        links: [
            "Seven Hills Hospital - 10 Mins",
            "Criticare Asia - 12 Mins",
            "Kokilaben Dhirubhai Ambani Hospital And Medical Research Institute - 28 Mins",

        ]
    },
    {
        name: "Schools And Offices",
        src: Schools,
        links: [
            "Bombay Cambridge International School - 8 Mins",
            "Holy Family High School And Junior College - 12 Mins",
            "Solitaire Corporate Park - 10 Mins",
            "Dynasty Business Park - 13 Mins",
            "MIDC - 14 Mins",
            "Seepz - 18 Mins",
            

        ]
    },
    {
        name: "Entertainment & Retail",
        src: Entertainment,
        links: [
            "The Orb - 5 Mins",
            "Carnival Cinemas - 10 Mins",
            "DMart - 15 Mins",
            "Phoenix Market City - 17 Mins",
            "Jio Mall - 22 Mins",
            "Acme Mall - 23 Mins",

        ]
    },
]

export const avanyaFeaturesData = [
    {
        name: "Transportation",
        src: Connectivity,
        links: [
            "Proposed Metro Station - 2 Mins",
            "Western Express Highway - 1 Min",
            "Dahisar Railway Station - 10 Mins",
            "International & Domestic Airports - 45 Mins",

        ]
    },
    {
        name: "Schools and Educational Institutions",
        src: Schools,
        links: [
            "Singapore International School - 2 Mins",
            "NM Dalmiya School - 6 Mins",
            "Waterstone Club - 5 Mins",
            "The Don Bosco High School - 8 Mins",
            "NM Dalmiya College - 8 Mins",

        ]
    },
    {
        name: "Entertainment & Retail",
        src: Entertainment,
        links: [
            "Thakur Mall - 2 Mins",
            "Inox Cinemas - 3 Mins",
            "Brand factory - 8 Mins",
            "Big Bazar - 2 Mins",
            "DMart - 6 Mins",

        ]
    },
    {
        name: "Health",
        src: Hospitals,
        links: [
            "Lifecare Hospital - 12 Mins",
            "Wockhardt Hospital - 15 Mins",

        ]
    },
    {
        name: "Parks & Gardens",
        src: Parkgarden,
        links: [
            "Sanjay Gandhi National Park - 10 Mins",
            "Water Kingdom and Essel World - 55 Mins",

        ]
    },
]


export const parkwoodsFeaturesData = [
    {
        name: "Transportation",
        src: Connectivity,
        links: [
            "Proposed Metro Station - 2 Mins",
            "Eastern Express Highway - 15 Mins",
            "Western Express Highway - 15 Mins",


        ]
    },
    {
        name: "Business Parks",
        src: Hotels,
        links: [
            "202 Hamilton Hiranandani Business Park - 10 Mins",
            "Tata Consultancy Service Olympus - 12 Mins",
            "Thane One Corporate Park - 18 Mins",
            "Orion Business Park - 20 Mins",

        ]
    },
    {
        name: "Entertainment & Retail",
        src: Entertainment,
        links: [
            "DMart (situated on the larger property) - 1 Min",
            "Hypercity - 3 Mins",
            "Viviana Mall - 20 Mins",

        ]
    },
    {
        name: "Schools and Educational Institutions",
        src: Schools,
        links: [
            "Podar International School - 10 Mins",
            "Orchid International School - 10 Mins",
            "Vasant Vihar High School - 15 Mins",

        ]
    },
    {
        name: "Health",
        src: Hospitals,
        links: [
            "Hiranandani Hospital - 10 Mins",
            "Jupiter Hospital - 20 Mins",

        ]
    },
]


export const astrumFeaturesData = [
    {
        name: "Entertainment & Shopping",
        src: Entertainment,
        links: [
            "Metro Mart - 1 min",
            "Hypercity - 8 Mins",
            "Inorbit Mall - 8 Mins",
            "Oberoi Mall - 8 Mins",
            "PVR Oberoi - 8 Mins",
            "Goregaon Sports Club - 12 Mins",
            "DMart - 15 Mins",
            "Infinity Mall Malad - 15 Mins",
            "The Hub Mall - 18 Mins",


        ]
    },
    {
        name: "Hotels",
        src: Hotels,
        links: [
            "The Westin Mumbai - 10 Mins",
            "Radisson Mumbai - 5 Mins",
            "Residency Sarovar Portico - 10 Mins",
            "The Fern Hotel - 13 Mins",

        ]
    },
    {
        name: "Schools and Educational Institutions",
        src: Schools,
        links: [
            "Gokhuldham High School - 15 Mins",
            "Yashodham School - 9 Mins",
            "Oberoi International School - 10 Mins",
            "Play Mate Pre School - 9 Mins",

        ]
    },
    {
        name: "Offices",
        src: Offices,
        links: [
            "DLH Park - 8 Mins",
            "Commerz I & II - 8 Mins",
            "Nesco IT Park - 15 Mins",
            "Lotus Business Park - 12 Mins",
            "Kotak Infinity Parks - 16 Mins",

        ]
    },
    {
        name: "Health",
        src: Hospitals,
        links: [
            "Lifeline Medicare Hospital - 8 Mins",
            "Vivanta Hospital - 13 Mins",
            "Gokhuldham Medical Center - 12 Mins",
            "Zenith Multispeciality Hospita - 22 Mins",

        ]
    },
]



export const degoaFeaturesData = [
    {
        name: "Transportation",
        src: Connectivity,
        links: [
            "Dabolim Airport - 20 Mins",
            "Mopa Airport - 40 Mins",


        ]
    },
    {
        name: "Nearby Landmarks",
        src: Nearby,
        links: [
            "Grand Hyatt Goa - 2 Mins",
            "St. Cruz, Taleigao, Panjim - 9 Mins",
            "Panjim - 14 Mins",
            "Panjim Convention Centre - 14 Mins",

        ]
    },
    {
        name: "Tourist Hotspots",
        src: TouristHotspot,
        links: [
            "Dona Paula - 12 Mins",
            "Miramar Beach - 16 Mins",
            "Offshore Casinos Jetty - 14 Mins",
            "Candolim Beach - 35 Mins",
            "Baga Beach - 35 Mins",
            "Madgaon - 35 Mins",

        ]
    },
    {
        name: "Schools and Educational Institutions",
        src: Schools,
        links: [
            "Goa Medical College and Hospital - 8 Mins",
            "Don Bosco Panjim - 18 Mins",
            "Proposed School - 2 Mins",
            // "Don Bosco - 5 Mins",
            // "International School, Goa Velha - 15 Mins",
            // "National Institute of Oceanography - 14 Mins"

        ]
    },
    {
        name: "Health",
        src: Hospitals,
        links: [
            "Goa Medical College and Hospital - 6 Mins",
            "Manipal Hospital - 9 Mins",

        ]
    },
]


export const shristiFeaturesData = [
    {
        name: "Schools and Educational Institutions ",
        src: Schools,
        links: [
            "N L Dalmia School - 2 Mins",
            "N L Dalmia Management College - 2 Mins",
            "Royal College - 2 Mins",
            "The Don Bosco High School - 6 Mins",
            "Singapore International School - 10 Mins",
            "GCC International School - 11 Mins",
            "Podar International School - 11 Mins",
            "RBK Global School - 20 Mins",


        ]
    },
    {
        name: "Health",
        src: Hospitals,
        links: [
            "Bhaktivedanta Hospital & Research Institute - 3 Mins",
            "Global Hospital - 7 Mins",
            "Wockhardt Hospital - 9 Mins",


        ]
    },
    {
        name: "Shopping",
        src: shopping,
        links: [
            "Thakur Mall - 7 Mins",
            "D'Mart - 7 Mins",
            "Brand Factory - 12 Mins",
            "Star Bazaar - 12 Mins",
            "Mins Maxus Mall - 21",


        ]
    },
    {
        name: "Transportation",
        src: Connectivity,
        links: [
            "Western Express Highway - 5 Mins",
            "Mira Road Station - 7 Mins",
            "Dahisar Check Naka - 8 Mins",
            "Kashimira Junction - 13 Mins",
            "Proposed Pandurang Wadi Metro Station - 3 Mins",
            "Proposed Kashigaon Metro Station - 7 Mins",
            "Proposed Coastal Road (Bhayandar) - 16 Mins",

        ]
    },
    {
        name: "Parks & Gardens",
        src: Parkgarden,
        links: [
            "Sanjay Gandhi National Park - 16 Mins",
            "Water Kingdom and Essel World - 50 Mins",

        ]
    },
]


export const raetFeaturesData = [
    {
        name: "Transportation",
        src: Connectivity,
        links: [
            "Dabolim Airport - 24 Mins",
            "Mopa Airport - 40 Mins",


        ]
    },
    {
        name: "Nearby Landmarks",
        src: Nearby,
        links: [
            "Grand Hyatt Goa Residences - 2 Mins",
            "Panjim - 12 Mins",
            "Panjim Convention Centre - 12 Mins",

        ]
    },
    {
        name: "Tourist Hotspots",
        src: TouristHotspot,
        links: [
            "Dona Paula - 12 Mins",
            "Miramar Beach - 16 Mins",
            "Offshore Casinos Jetty - 12 Mins",
            "Candolim Beach - 30 Mins",
            "Baga Beach - 35 Mins",
            "Madgaon - 35 Mins",

        ]
    },
    {
        name: "Schools and Educational Institutions",
        src: Schools,
        links: [
            "Goa Medical College and Hospital - 6 Mins",
            // "Bambolim, Education Hub - 5 Mins",
            "Proposed IB School - 2 Mins",
            "National Institute of Oceanography - 12 Mins"

        ]
    },
    {
        name: "Health",
        src: Hospitals,
        links: [
            "Goa Medical College and Hospital - 3 Mins",
            "Manipal Hospital - 8 Mins",

        ]
    },
]


export const amenitiesFeaturesData = [
    {
        name: "Transportation",
        src: Connectivity,
        links: [
            "Dabolim Airport - 25 Mins",
            "Mopa Airport - 40 Mins",


        ]
    },
    {
        name: "Nearby Landmarks",
        src: Nearby,
        links: [
            "Grand Hyatt Goa Residences - 1 min",
            "Panjim - 14 Mins",
            "Panjim Convention Centre - 13 Mins",

        ]
    },
    {
        name: "Tourist Hotspots",
        src: TouristHotspot,
        links: [
            "Dona Paula - 13 Mins",
            "Miramar Beach - 18 Mins",
            "Offshore Casinos Jetty - 15 Mins",
            "Candolim Beach - 35 Mins",
            "Baga Beach - 35 Mins",
            "Madgaon - 35 Mins",

        ]
    },
    {
        name: "Schools and Educational Institutions",
        src: Schools,
        links: [
            "Goa Medical College and Hospital - 6 Mins",
            // "Don Bosco Panjim - 18 Mins",
            "Proposed IB School - 2 Mins",
            "National Institute of Oceanography - 14 Mins"


        ]
    },
    {
        name: "Health",
        src: Hospitals,
        links: [
            "Goa Medical College and Hospital - 6 Mins",
            "Manipal Hospital - 10 Mins",

        ]
    },
]






// module.exports = {
//     divumfeaturesData,
//     lumaFeaturesData,
//     avanyaFeaturesData,
//     parkwoodsFeaturesData,
//     astrumFeaturesData,
//     degoaFeaturesData,
//     shristiFeaturesData
// }